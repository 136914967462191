/* login.css */

/* General Container Styling */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    margin: auto;
    padding-top: 150px;
    padding-bottom: 150px;
  }
  
  /* Form Styling */
  .login-container form {
    width: 100%;
    margin-bottom: 20px;
  }
  
  /* Label Styling */
  .login-container label {
    display: block;
    margin-bottom: 5px;
  }
  
  /* Input Styling */
  .login-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Button Styling */
  .login-container button {
    padding: 10px 20px;
    background-color: #32C3F2;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Button Hover Styling */
  .login-container button:hover {
    background-color: #28a0d8;
  }
  
  /* Error Message Styling */
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  /* Upload Container Styling */
  .upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: #32C3F2 solid 4px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
  }
  
  /* File Input Styling */
  .upload-container input[type="file"] {
    margin-bottom: 20px;
  }
  
  /* Upload Button Styling */
  .upload-container button {
    padding: 10px 20px;
    background-color: #32C3F2;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Upload Button Hover Styling */
  .upload-container button:hover {
    background-color: #28a0d8;
  }
  
  /* Video Styling */
  .upload-container video {
    margin-top: 20px;
    max-width: 100%;
    border-radius: 10px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .login-container, .upload-container {
      width: 90%;
    }
  }
  