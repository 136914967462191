/* General Styles */
.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    padding-top: 100px;
    border: #32C3F2 solid 4px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    margin: 50px auto;
    margin-top: 200px;
    text-align: center;

  }
  
  .upload-container input[type="file"] {
    margin-bottom: 20px;
  }
  
  .upload-container button {
    padding: 10px 20px;
    background-color: #32C3F2;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .upload-container button:hover {
    background-color: #1a9cd8; /* Slightly darker blue for hover effect */
  }
  
  .upload-container progress {
    width: 100%;
    margin-top: 20px;
  }
  
  .upload-container video,
  .upload-container img {
    margin-top: 20px;
    max-width: 100%;
    border-radius: 10px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .upload-container {
      max-width: 100%;
      padding: 10px;
      margin-top: 100px; /* Margin top for tablet screens */
    }
  
    .upload-container label {
      font-size: 1.5em;
    }
  }
  
  @media (max-width: 480px) {
    .upload-container {
      margin: 20px auto;
      margin-top: 150px; /* Margin top for mobile screens */
      width: 90%;
      max-width: 320px; /* Adjust the max width for small screens */
    }
  
    .upload-container label {
      font-size: 1.2em;
    }
  
    .upload-container button {
      padding: 8px 16px;
    }
  }
  