.explore-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 150px;
  padding-bottom: 150px;
}

.explore-page h1 {
  font-size: 3rem;
  color: white;
  margin-bottom: 1rem; /* Add some space below the heading */
}

.explore-page p {
  font-size: 1.5rem;
  color: white;
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .explore-page h1 {
    font-size: 2.5rem;
  }

  .explore-page p {
    font-size: 1.25rem;
  }
}

@media (max-width: 992px) {
  .explore-page h1 {
    font-size: 2rem;
  }

  .explore-page p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .explore-page h1 {
    font-size: 1.5rem;
  }

  .explore-page p {
    font-size: 0.875rem;
  }
}

@media (max-width: 576px) {
  .explore-page h1 {
    font-size: 1.25rem;
  }

  .explore-page p {
    font-size: 0.75rem;
  }
}
