/* Basic reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Arial', sans-serif;
background-color: #133343;
color: #EDF4F2;
}

/* Main container for home component */
.home {
    padding: 40px;
    max-width: 2150px;
    margin: 0 auto; /* Center the content */
}

/* Upper section styling */
.upper-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Ensure wrapping on smaller screens */
}

.text-container {
    flex: 1;
    max-width: 50%;
    margin-right: 20px; /* Add space between text and image */
}

.text-container h1 {
    font-size: 36px;
    margin-bottom: 20px;
}

.text-container .heading-highlight {
    font-size: 50px;
    color: #EBB645; /* Color for "Synergy Edge" */
}

.text-container p {
    font-size: 18px;
    line-height: 1.5;
text-align: justify;
word-spacing: 0.1em;
}

/* Social media icons styling */
.social-icons {
    display: flex;
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.icon {
    display: inline-block;
    width: 40px;
    height: 40px;
}

.icon-image {
    width: 100%;
    height: auto;
}

.image-container {
    flex: 1;
    max-width: 50%;
    display: flex; /* Ensures that content can be centered */
    justify-content: center; /* Horizontally centers the image */
}

.image-container img {
    margin-top: 30px;
    width: 90%;
    height: auto;
    border-radius: 10px; /* Optional: rounded corners */
}

/* Lower section styling */
.lower-section {
    display: flex;
    justify-content: space-between; /* Ensures even spacing between items */
    align-items: center;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    color: #EDF4F2;
    margin-bottom: 40px;
    margin-top: 95px;
    padding: 0 40px; /* Optional: Adds padding to the left and right */
}

/* Icon section for individual items */
.icon-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
    font-size: 18px;
    min-width: 150px;
    margin: 0 auto; /* Ensures it centers on smaller screens */
}

.icon-image {
    width: 60px;
    height: auto;
    margin-bottom: 10px;
}

.icon-section p {
    font-size: 20px;
    color: #EBB645;
}

/* Skills section styling */
.skills-section {
    margin-top: 80px;
    text-align: center;
}

.skills-section h2 {
    font-size: 46px;
    margin-bottom: 20px;
    color: #EDF4F2;
    margin-bottom: 100px;
}

.skills-grid {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.skills-row {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Reduced gap for better balance */
}

.skill-column {
    flex: 1;
    background:/* url('../assets/divBG.webp'), */
     linear-gradient(to top, #EBB645, #f4d697, #e3d5b4, #ffffff, #ffffff); 
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
    padding: 20px;
    border-radius: 8px;
    color: #133343;
    font-size: 18px;
    text-align: center;
}

/* Skill content styling */
.skill-content {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 20px;
}

.skill-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.skill-image {
    max-width: 60px; /* Adjust the width as needed */
    height: auto;
}

.btn-contact {
    padding: 10px 20px;
    background-color: #313133; /* Change to your preferred button color */
    color: white;
    text-decoration: none;
    border-radius: 50px;
    transition: background-color 0.3s;
}

.btn-contact:hover {
    background-color: #58585b; /* Hover color */
}

.skill-info {
    text-align: left;
}

.skill-info h3 {
    margin-bottom: 15px;
    text-align: justify;
}

.skill-info p {
    font-size: 14px;
    font-weight: 600;
}

/* Who Am I section styling */
.who-am-i-section {
    margin-top: 100px; /* Adjust margin as needed */
    text-align: center;
}

.who-am-i-section h2 {
    font-size: 36px;
    color: #EDF4F2; /* Match with your design theme */
    margin-bottom: 20px;
    margin-top: 20px;
}

.who-am-i-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start; /* Align items to the top for better layout */
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
    max-width: 2150px;
}

.who-am-i-content div {
    max-width: 1000px; /* Limit width for readability */
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.about-me h2, .education h2, .work-experience h2 {
    font-size: 28px;
    color: #EDF4F2;
    margin-bottom: 10px;
    
}

.about-me p, .education p {
    font-size: 18px;
    line-height: 1.5;
    color: #EDF4F2;
    margin-bottom: 20px;
    margin-top: 20px;
}

.skills, .libraries, .frameworks, .animation-tools {
    margin-bottom: 20px;
}

.skills ul, .libraries ul, .frameworks ul, .animation-tools ul {
    list-style-type: none;
    padding: 0;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap if necessary */
    gap: 15px; /* Space between items */
    justify-content: center; /* Center items horizontally */
}

.skills li, .libraries li, .frameworks li, .animation-tools li {
    font-size: 16px;
    color: #EDF4F2;
    padding: 5px 10px; /* Add some padding around items */
    border: 1px solid #EBB645; /* Optional: add border for better visual separation */
    border-radius: 5px; /* Optional: rounded corners */
}

/* Media queries for responsive design */
@media (max-width: 1024px) {
    .upper-section {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .text-container {
        max-width: 100%;
        margin-right: 0;
    }

    .image-container {
        max-width: 100%;
        margin-top: 20px;
    }

    .social-icons {
        justify-content: center;
    }

    .who-am-i-content {
        flex-direction: column; /* Stack items vertically on medium screens */
        text-align: center; /* Center text on medium screens */
    }

    .who-am-i-icon {
        width: 60px; /* Adjust size for medium screens */
    }

    .who-am-i-content p {
        font-size: 16px; /* Adjust font size for medium screens */
    }
}

@media (max-width: 768px) {
    .lower-section {
        flex-direction: column;
        justify-content: center;
        gap: 20px; /* Add space between rows for small screens */
        padding: 20px; /* Optional: Adjust padding for smaller screens */
    }

    .icon-section {
        margin: 10px 0;
    }

    .skills-row {
        flex-direction: column;
        gap: 20px;
    }

    .skills-section h2 {
        font-size: 36px;
    }

    .who-am-i-content {
        gap: 15px; /* Adjust spacing for smaller screens */
    }

    .who-am-i-icon {
        width: 50px; /* Further adjust icon size */
    }

    .who-am-i-content p {
        font-size: 14px; /* Adjust font size for smaller screens */
        max-width: 100%; /* Allow text to take full width */
    }
}

@media (max-width: 480px) {
    .social-icons {
        gap: 30px; /* Adjusted for small screens */
    }

    .icon {
        width: 30px; /* Reduce icon size */
        height: 30px;
    }

    .skills-section h2 {
        font-size: 30px;
    }

    .skill-column {
        padding: 15px;
    }

    .icon-image {
        width: 50px; /* Further reduce icon size */
    }

    .text-container h1 {
        font-size: 28px; /* Smaller text for better mobile fit */
    }

    .text-container p {
        font-size: 16px;
    }

    .who-am-i-content {
        gap: 10px; /* Reduce spacing for small screens */
    }

    .who-am-i-icon {
        width: 40px; /* Further reduce icon size */
    }

    .who-am-i-content p {
        text-align: center;
        font-size: 14px;
        padding: 0 10px;
    }

}

