/* Basic reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Arial', sans-serif;
    background-color: #28282A;
    color: #EDF4F2;
}

.footer {
    position: relative;
    color: #EDF4F2;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    background-color: #1e465a;
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
    position: relative;
}

.footer-section {
    flex: 1;
    padding: 0 20px;
}

.footer-logo {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
}

.footer-section h3 {
    font-size: 20px;
    color: #EBB645;
    margin-bottom: 10px;
    cursor: pointer; /* Change cursor to pointer for clickable headings */
    position: relative;
}

.footer-section p {
    font-size: 16px;
    line-height: 1.5;
    text-align: left;

}

.footer-list {
    list-style-type: disc;
    padding-left: 20px;
}

.footer-list li {
    margin-bottom: 15px;
    font-size: 16px;
}

.contact-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-details {
    text-align: center;
}

.qr-section {
    margin-top: 20px; /* Space between contact details and QR code */
    text-align: center;
}

.qr-code {
    width: 150px; /* Adjust size as needed */
    height: auto;
}

.footer-social-icons {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 10px;
    padding-bottom: 30px;
}

.icon {
    display: inline-block;
    width: 40px;
    height: 40px;
}

.icon-image {
    width: 100%;
    height: auto;
}

.footer-bottom {
    text-align: center;
    font-size: 14px;
}

.footer-bottom p {
    margin-top: 20px;
    text-align: justify;

}

/* Responsive Design */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        width: 100%;
        padding: 10px 0;
        text-align: center;
    }

    .dropdown-content {
        display: none; /* Hide content by default */
    }

    .footer-section.open .dropdown-content {
        display: block; /* Show content when section is open */
    }

    .dropdown-heading {
        position: relative;
    }

    .arrow {
        display: inline-block;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        border-left: 2px solid #EDF4F2;
        border-bottom: 2px solid #EDF4F2;
        transform: rotate(-45deg);
        transition: transform 0.3s;
    }

    .arrow.open {
        transform: rotate(135deg); /* Arrow rotated when open */
    }

    .footer-section.open .dropdown-heading {
        background-color: #1E465A; /* Optional: background color when open */
    }
}
